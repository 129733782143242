<template>
	<v-container>
		<v-card flat>
			<v-toolbar flat color="#0f2e8c">
				<h3 class="white--text">Danh Sách Tracking Trong Hàng Chờ</h3>
			</v-toolbar>
			<v-card-title>
				<v-spacer />
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Tìm kiếm"
					single-line
					hide-details
				/>
			</v-card-title>
			<v-card-text>
				<v-data-table
					:loading="loading"
					:headers="headers"
					:items="items"
					:search="search"
					loading-text="Đang tải dữ liệu..."
					:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
					:items-per-page="20"
				>
					<template v-slot:[`item.DRC`]="{ item }">
						{{item.D}}-{{item.R}}-{{item.C}}
					</template>
					<template v-slot:[`item.Delete`]="{ item }">
						<v-btn
							color="red"
							dark
							small
							@click="doDelete(item._id)"
						>
							Xóa
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import Loading from '@/components/base/Loading.vue';
import Query from '@/plugins/query/partner';
import {Status} from '@/plugins/helper/dstatus';
import {mapGetters} from "vuex";
export default {
	name: "PTrack",
	components:{
		Loading,
	},
	data(){
		return {
			search: null,
			loading: false,
			items: [],
			headers:[
				{ text: 'Tracking', value: 'Tracking', align: 'center',width: "15%",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Dài - Rộng - Cao', value: 'DRC', align: 'center',width: "20%",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Khối lượng', value: 'KL', align: 'center',width: "15%",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Phí Ship', value: 'Notes', align: 'center',width: "15%",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Ghi chú', value: 'Notes', align: 'center',width: "25%",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Xóa', value: 'Delete', align: 'center',width: "10%",class:"grey lighten-2 black--text",sortable:false },
			],
		}
	},
	computed: {
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		notice(){
			return new Status(this.$swal);
		}
	},
	methods:{
		...Query,
		doDelete(id){
			this.loading = true;
			this.qrRemoveTrack(id).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success,error} = json;
				if(success){
					this.notice.success('Đã xóa Tracking thành công !!!');
					const index = this.items.findIndex(item=>item._id == id);
					this.items.splice(index,1);
				}
				if(error){
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e.message || 'Có lỗi xảy ra !!!');
			}).finally(()=>{
				this.loading = false;
			})
		},
		doDownload(){
			this.loading = true;
			this.qrPendingTrack().then(resp=>{
				return resp.json();
			}).then(json=>{
				const {data,error} = json;
				if(data) this.items = data;
				if(error){
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e.message || 'Không thể tải danh sách hàng chờ !!!');
			}).finally(()=>{
				this.loading = false;
			})
		},
	},
	mounted() {
		this.doDownload();
	}
}
</script>

<style scoped>

</style>